module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-55LSDKV5","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"National Zakat Foundation Australia","short_name":"NZF","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7469ca784bf89a3416180e11f94de7a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"nzf2020","accessToken":"MC5YcHFBUkJJQUFDRUF1ODcw.77-9SCx4bu-_ve-_ve-_ve-_vQbvv73vv73vv70xKu-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vWXvv73vv73vv70EZO-_vUMN","routes":[{"type":"about_page","path":"/about"},{"type":"account_page","path":"/account"},{"type":"apply_page","path":"/apply"},{"type":"calculate_page","path":"/calculate"},{"type":"case","path":"/case/:uid"},{"type":"cases_page","path":"/cases"},{"type":"contact_page","path":"/contact"},{"type":"donation_page","path":"/pay"},{"type":"faq_page","path":"/faq"},{"type":"fidyah_donation_page","path":"/pay/fidyah"},{"type":"fitr_donation_page","path":"/pay/fitr"},{"type":"global","path":"/"},{"type":"guides_page","path":"/guides"},{"type":"home_page","path":"/"},{"type":"landing_page","path":"/:uid"},{"type":"learn_page","path":"/learn"},{"type":"member","path":"/about"},{"type":"partner","path":"/partner/:uid"},{"type":"pay_page","path":"/pay"},{"type":"privacy_policy_page","path":"/privacy"},{"type":"program","path":"/program/:uid"},{"type":"programs_page","path":"/programs"},{"type":"ramadan_donation_page","path":"/pay/ramadan"},{"type":"sadaqah_donation_page","path":"/pay/sadaqah"},{"type":"tainted_wealth_donation_page","path":"/pay/tainted_wealth"},{"type":"zakat_donation_page","path":"/pay/zakat"}],"promptForAccessToken":true,"apiEndpoint":"https://nzf2020.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
